<template>
  <section>
    <div class="text-center" v-bind:class="typeOfMessage">
      <slot></slot>
    </div>
  </section>
</template>
<script>
export default {
  props: ["type"],
  data() {
    return {            
    };
  },
  computed: {
    typeOfMessage() {
      if(this.type == "error")
      {
        return "errorInfo";
      }
      if(this.type == "warning")
      {
        return "warningInfo";
      }
      if(this.type == "success")
      {
        return "successInfo";
      }
      return "messageInfo";
    }
  },
};
</script>
